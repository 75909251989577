import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import debounce from 'lodash/debounce';

export const Circles = ({ data, handleClick, selected }) => {
  const [width, setWidth] = useState(0);
  const containerRef = useRef("no selection");

  useEffect(() => {
    function updateWidth() {
      setWidth(containerRef.current.clientWidth / (data.length + 1));
    }
    const handleResize = debounce(updateWidth, 500);
    updateWidth();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data.length]);

  useLayoutEffect(() => {
    if (Array.isArray(data)) {
      
      const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400"];
      const update = d3
        .select(containerRef.current)
        .selectAll('circle')
        .data(data);

      update
        .enter()
        .append('circle')
        .on("click", (d) => {
          return (handleClick(d.target.__data__))
        })
        .merge(update)
        .attr('r', (d) => d.value)
        .attr('cx', (_, i) => width * (i + 1))
        .attr('cy', () => Math.random() * 100)
        .style("opacity", (d) =>  ((d === selected || !selected  ? 1 : 0.2)))
        .style("stroke", (_, i) => colors[i % data.length])
        .style("fill", (_, i) => colors[i % data.length]);

      update.exit().remove();
    }
  }, [data, selected, width]);

  return (
     <svg width="100%" height="350px" ref={containerRef}>
    {/* <svg ref={containerRef}> */}
      <g transform="translate(0, 100)"/>
    </svg>
  );
};