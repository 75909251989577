import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import debounce from 'lodash/debounce';

export const BarChart = ({ data, selected, handleClick }) => {

  const [width, setWidth] = useState(0);
  const containerRef = useRef("no selection");
  const tooltipRef = useRef();

  useEffect(() => {
    function updateWidth() {
      setWidth(containerRef.current.clientWidth / (data.length + 1));
    }
    const handleResize = debounce(updateWidth, 500);
    updateWidth();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data.length]);

  useLayoutEffect(() => {
    if (Array.isArray(data) && containerRef?.current) {
      
      // set layout parameters
      const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400"];
      const max_bar_width = 100;
      const svg_height = 350;
      const bar_color = "#FFCB65";
      const top_offset = 50;
      const bottom_offset = 50;
      
      
      // create svg
      const svg = d3.select(containerRef?.current)
                  .attr("fill", "red")
                  .attr("width", '100%')
                  .attr("height", svg_height);

      // create a tooltip
      var tooltip = d3.select(tooltipRef?.current)
          .style("position", "absolute")
          .style("visibility", "hidden")
          .style("z-index", "10")
          .style("fill", "orange")
          .style("width", "100px")
          .style("height", "100px")
          .text("I'm a circle too!");

    //
    d3.select(containerRef?.current)
        .on("mouseover", function(){return tooltip.style("visibility", "visible");})
        .on("mousemove", function(event){return tooltip.style("top", (event.pageY-1)+"px").style("left",(event.pageX)+"px");})
        //.on("mousemove", function(event){return tooltip.style("top", "20px").style("left","20px");})
        .on("mouseout", function(){return tooltip.style("visibility", "hidden");});

      // to make graph responsive calcuate we set width 100%
      // here we calculate width in pixels
      const svg_width = svg.node().getBoundingClientRect().width;
      
      // decide bar width depending upon available space and no. of bars to plot
      let bar_width = Math.round((svg_width - 60) / data.length);
      if (bar_width > max_bar_width) {
        bar_width = max_bar_width;
      }

      // spacing between two bars
      // instead of having a fixed value we set it dynamically
      const spacing = 0.15 * bar_width;

      // to center align graph we move it from left to right
      // this is applicable if there's any space left
      let left_offset = Math.round((svg_width - bar_width*data.length)/2);
      if (left_offset < 0) {
        left_offset = 0;
      }

      // create scale
      const scale = d3.scaleLinear()
        .domain([0, Math.max(...data?.map((d) => (d.value)))])
        .range([0, svg_height - top_offset - bottom_offset]);

      // create scale for Y-Axis
      // we could have used scale above but for Y-Axis we need domain reversed
      const scale_y_axis = d3.scaleLinear()
        .domain([0, Math.max(...data?.map((d) => (d.value)))])
        .range([0, svg_height - top_offset - bottom_offset]);

      // append rect
      const rect = svg
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("click", (e, d) => {
          console.log(e);
          console.log(d);
          console.log(data.length);
          // return (handleClick(d.explicitOriginalTarget.__data__));
          return (handleClick(d));
        })
        .attr("fill", (d, i) => colors[i % data.length])
        .attr("data", d => d)
        .attr("x", (d, i) => left_offset + bar_width * i)
        .attr("y", d => svg_height - bottom_offset - scale(d.value))
        .attr("width", bar_width - spacing)
        .attr("height", d => scale(d.value))
        .attr("opacity", (d) =>  ((d === selected || !selected  ? 1 : 0.2)))
        ;

       
          
      // svg.exit().remove();
    }   
  }, [data, selected, width]);

  return (
     <>
      <svg width="100%" height="350px" ref={containerRef}/>
      <div className='tooltip' ref={tooltipRef}>I'm a circle</div>
    </>
  );
};