import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../components/breadcrumbs";
import { useNuggets } from "../helpers/nuggetContext";
import PictureCard from "../components/pictureCard";


const RecruitmentType = () => {
    
        var headerStyle = {
            backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://images.unsplash.com/photo-1598257006626-48b0c252070d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
         };

        const nuggets = useNuggets();
        const params = useParams();

        return (
            <>
                <h2 className="page-header" style={headerStyle}>{"Opportunites for "+params.type}</h2>
                <Breadcrumbs/>
       
                <section className="section">
                    { params.type === "School Leavers" &&
                        <div>
                            <h4>Recently left school?</h4>
                            <h4>Like maths?</h4>
                            <h4>Come join us.</h4>
                        </div>
                    }
                    { params.type === "Graduates" &&
                        <div>
                            <h4>Recently graduated?</h4>
                            <h4>Like maths?</h4>
                            <h4>Come join us.</h4>
                        </div>
                    }
                     { params.type === "Professionals" &&
                        <div>
                            <h4>Already working in financial services?</h4>
                            <h4>Not a sociopath?</h4>
                            <h4>Come join us.</h4>
                        </div>
                    }
                    { params.type === "Contractors" &&
                        <div>
                            <h4>Already working in financial services?</h4>
                            <h4>Scared of being tied down by one company?</h4>
                            <h4>Let's find you something to do.</h4>
                        </div>
                    }

                </section>
                
                <section className="section">
                    <h4>(we could put lots of useful info here).</h4>
                    <h4>(or we could put it all in the nugget...).</h4>
                    <div className="cards-container" >
                        {nuggets
                        .filter((nug) => nug.type == "Recruitment")
                        .filter((nug) => nug.title == params.type)
                        .map(({ id, title, description, image }) => (
                            <PictureCard 
                            key={id} 
                            title={title} 
                            description={description}
                            navigateTo = {"/resources/"+id}
                            image={image} />
                        ))}
                    </div>
                </section>


         
                
        </>
    );
};

export default RecruitmentType;