import React from "react";
import '../App.css';
import Minicard from '../components/minicard.js'
import { useNuggets } from "../helpers/nuggetContext";
import { Breadcrumbs } from "../components/breadcrumbs";
import { NavLink } from "react-router-dom";
import PictureCard from "../components/pictureCard";
import PictureSlice from "../components/pictureSlice";
import Highlights from "../components/highlights";

const Home = () => {

    const nuggets = useNuggets();

    var headerStyle = {
      backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://media.istockphoto.com/id/178011209/photo/house-on-a-lifebelt-rising-sea-levels-global-warming.jpg?s=612x612&w=0&k=20&c=6zvrxG3X_PdzUhHJ7ubYl0uAm-fSfBIpMa7b5NLT_7E=)",
      padding: "20px",
      display: "flex"
     };
    
    return (
      <>
        <h3 className="page-header" style={headerStyle}>Applying an actuarial mindset to business problems.</h3> 
        <Breadcrumbs/>
   
        <section className="section">
          <h4>Welcome to ABC consulting.</h4>
          <h4>We recruit clever people and help them achieve their career goals.</h4>
          <h4>Does that sound like fun to you?</h4>
              <NavLink 
                    to={"/opportunities"}
                    replace={false} 
                    className="list-link">
                      <h4>If it does, then click here to find out how to join us.</h4>
              </NavLink>
          <h4>If you are looking for help on your project, then keep reading.</h4>
        </section>
      
        <section id="section-industries" className="section">
            <h4>We provide services to a "plethora" of industries:</h4>
            <div id="industry-cards" className="cards-container" >
              {nuggets
                .filter((nug) => nug.type === "Industry")
                .map(({ id, title, description, image }) => (
                  <Minicard 
                    key={id} 
                    title={title} 
                    // description={description} 
                    navigateTo = {"/services/"+title}
                    image={image} />
              ))}
            </div>
        </section>

        <section id="section-departments" className="section">
          <div className="section-panel">
            <h4>We work with many client functions...</h4>
            <ul className='simple-list'>
            {nuggets
                .filter((nug) => nug.type === "Department")
                .slice(0, 11)
                .map(({ title, id }) => (
                  <li key={id}> 
                    <NavLink 
                      to={"/resources/"+id}
                      replace={false} 
                      className="list-link">
                        {title}
                    </NavLink>
                  
                  </li>
              ))}
            </ul>
          </div>
          <div className="section-panel">
            <h4>...helping with a variety of activities.</h4>
            <ul className='simple-list'>
            {nuggets
                .filter((nug) => nug.type === "Activity")
                .slice(0, 11)
                .map(({ title, id }) => (
                  <li key={id}>
                    <NavLink 
                      to={"/resources/"+id}
                      replace={false} 
                      className="list-link">
                        {title}
                    </NavLink>
                  
                  </li>
              ))}
            </ul>
          </div>
        </section>

        <section id="section-delivery" className="section">
            <h4>We help deliver projects in four different ways:</h4>
            <div id="delivery-cards" className="cards-container" >
              {nuggets
                .filter((nug) => nug.type === "Delivery")
                .map(({ id, title, description, image }) => (
                  <Minicard 
                    key={id} 
                    title={title} 
                    navigateTo = {"/resources/"+id}
                    image={image} />
              ))}
            </div>
        </section>

        <section id="section-expertise" className="section">
            <div className="cards-container">
              <div className="card card-title">
                <h4>
                  We have a depth of experience in a wide range of topics...
                </h4>
              </div>
              {nuggets
                .filter((nug) => nug.type === "Expertise")
                .slice(0, 12)
                .map(({ id, title, description, image }) => (
                  <PictureCard 
                    key={id} 
                    title={title} 
                    description={description} 
                    navigateTo = {"/resources/"+id}
                    image={image} />
              ))}
            </div>
        </section>

        <section id="section-highlights" className="section">
          <h4>We don't rest on our laurels, but we are proud of our track record.</h4>
          <Highlights/>
        </section>

        <section id="section-testimonials" className="section">
          <div className="picture-slice-container">
                <h4>
                  This is what some of our clients think of us...
                </h4>
              {nuggets
                .filter((nug) => nug.type === "Testimonial")
                .slice(0, 3)
                .map((nug, idx) => (
                  <PictureSlice 
                    key={nug.id} 
                    description={nug.title + ": " + nug.description} 
                    content={nug.content}
                    pictureOnLeft={idx % 2 == 1}
                    image={nug.image} />
              ))}
          </div>
        </section>

      </>
    );
};

export default Home;