import React from "react";
import { NavLink } from "react-router-dom";

export default function Card({ title, type, description, image, handleClick, navigateTo }) {

    return (
      <div className="card">
        
        {navigateTo && <NavLink to={navigateTo} replace={false}><img className="card-image" src={image} alt=""/></NavLink>}
        {!navigateTo && <img className="card-image" src={image} alt=""/>}
         
        <div className="card-text">
          <h4 className="card-title">{title}</h4>
          <h5 className="card-type">{type}</h5>
          <p>{description}</p>
           
         {handleClick && <button 
                class="btn btn-primary btn-block" 
                onClick={handleClick}>
                 Learn more...
          </button>}
          {navigateTo && <NavLink to={navigateTo} replace={false} className="btn-custom">Learn more...</NavLink>}
         
        </div>
      </div>
    );
  }
  
  