import React, { useState, useCallback } from "react";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Circles } from "../components/circles";
import  { PieChart } from "../components/pieChart";
import { BarChart } from "../components/barChart";
import { StackedBarChart } from "../components/stackedBarChart";

const Test = () => {
    
    var headerStyle = {
        backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://blog.planview.com/wp-content/uploads/2014/01/AAEAAQAAAAAAAAOOAAAAJDVhMGZhZmJkLTJlMDQtNDExOS05YTliLTAyYWVkMTY5MmUzOQ.jpg)",
    };
    var chartStyle = {
        display: "inline-block",
        width: "25%",
        height: "400px",  
    };
    
    const [data, setData] = useState([ 
        {id: 1, category: "A", date: Date("2023/4/19"), value:10},
        {id: 2, category: "A", value:12},
        {id: 3, category: "B", value:4},
        {id: 4, category: "B", value:30},
        {id: 5, category: "B", value:16}
    ]);    
    
    const [selected, setSelected] = useState(null);

    function toggleSelection(obj)  {
        console.log("selected "+ JSON.stringify(obj));
        setSelected((prev) => { return(prev?.id === obj?.id ? null : obj)});
    }

    const updateData = useCallback(() => { 
        const values = data.map((obj) => {
            return({
                id: obj.id,
                category: obj.category,
                value: (5 + Math.round(Math.random() * 15))
            })
        })
        setData(values);
    }, []);

    return (
        <>
            <h2 className="page-header" style={headerStyle}>Test Area</h2>
            <Breadcrumbs/>
            <button onClick={updateData}>Create Random Data</button>
            <p>{"Selected: " + JSON.stringify(selected)}</p>
            <div style={chartStyle} >
                <Circles data={data} handleClick={toggleSelection} selected={selected}/>
            </div>
            <div style={chartStyle} >
                <PieChart data={data} handleClick={toggleSelection} selected={selected}/>
            </div>
            <div style={chartStyle} >
                <BarChart data = {data} handleClick={toggleSelection} selected={selected}/>
            </div>
            <div style={chartStyle} >
                <StackedBarChart data = {data} handleClick={toggleSelection} selected={selected}/>
            </div>
         </>
    );
};

export default Test;