import React from "react";
import { NavLink } from "react-router-dom";

export default function Minicard({ title, description, handleClick, navigateTo }) {
    return (
      <div className="minicard">
        {!navigateTo && <button className="btn-custom" onClick={handleClick}>
          <h4>{title}</h4>
          {description && <p>{description.substring(0,20)+"..."}</p>}   
        </button>}

        {navigateTo && <NavLink to={navigateTo} replace={false} className="btn-custom">
          <h4>{title}</h4>
          {description && <p>{description.substring(0,20)+"..."}</p>}
        </NavLink>}
         
      </div>
    );
  }
  
  