import React from "react";
import { useNuggets } from "../helpers/nuggetContext";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../components/breadcrumbs";
import Card from "../components/card";

const Nugget = () => {

    const params = useParams();
    const nuggets = useNuggets();
    
    return (
        <>
            {nuggets
            .filter((nugget) => nugget.id == params.id)
            .map((nugget) =>  (
                <div>
                    <div >
                        <img src={nugget.image} className="nugget-header-image" alt=""/>
                    </div>
                    
                    <Breadcrumbs/>
                    <div className="nugget-section">
                        <h4 className="nugget-title">{nugget.title}</h4>
                        <h5 className="nugget-sub-title">{nugget.type}</h5>
                        {nugget.description && <p>{nugget.description}</p>}
                        {nugget.content && <p>{nugget.content}</p>}
                    </div>
                    
                    { nugget.type == "Article" && 
                        <div className="nugget-section">
                            <h4>Here are some more articles</h4>
                            <div className="cards-container">
                                {nuggets
                                .filter((card) => card.type === "Article")
                                .filter((nugget) => nugget.id != params.id)
                                .slice(0, 3)
                                .map(({ id, title, type, description, image, content}) => (
                                    <Card 
                                    key={id} 
                                    title={title} 
                                    type={type}
                                    description={description}
                                    content={content}
                                    navigateTo={"/resources/"+id}
                                    image={image} />
                                ))}
                            </div>
                        </div>
                    }
                    

                    { nugget.type == "Expertise" && 
                        <div className="nugget-section">
                            <h4>Here are some other areas of expertise</h4>
                            <div className="cards-container">
                                {nuggets
                                .filter((card) => card.type === "Expertise")
                                .filter((nugget) => nugget.id != params.id)
                                .sort(() => (Math.random() > .5) ? 1 : -1)
                                .slice(0, 4)
                                .map(({ id, title, type, description, image, content}) => (
                                    <Card 
                                    key={id} 
                                    title={title} 
                                    type={type}
                                    description={description}
                                    content={content}
                                    navigateTo={"/resources/"+id}
                                    image={image} />
                                ))}
                            </div>
                        </div>
                    }

                    <div className="nugget-section">
                        <h4>And, you may also be interested in these...</h4>
                        <div className="cards-container">
                            {nuggets
                            .sort(() => (Math.random() > .5) ? 1 : -1)
                            .filter((nugget) => nugget.id != params.id)
                            .slice(0, 4)
                            .map(({ id, title, type, description, image, content}) => (
                                <Card 
                                key={id} 
                                title={title} 
                                type={type}
                                description={description}
                                content={content}
                                navigateTo={"/resources/"+id}
                                image={image} />
                            ))}
                        </div>
                    </div>
                 </div>
                        
                       
                         
            ))}

        </>
    );
};

export default Nugget;