import React from "react";
import { NavLink } from "react-router-dom";

export default function PictureSlice({ title, description, content, image, handleClick, navigateTo, pictureOnLeft }) {


    return (
      <div className="picture-slice">
        
        {pictureOnLeft && <img className="picture-slice-image" src={image} alt=""/>}
        {/* <div className="picture-slice-image-frame">
          <img className="picture-slice-image" src={image} alt=""/>
        </div> */}
        <div className="picture-slice-text">
          <h4 className="picture-slice-title">{title}</h4>
          <h5 className="picture-slice-description">{description}</h5>
          <p className="picture-slice-content">{content}</p>
          {navigateTo && <NavLink to={navigateTo} replace={false} className="btn-custom">Learn more...</NavLink>}
         </div>
        
        {!pictureOnLeft && <img className="picture-slice-image" src={image} alt=""/>}
      
      </div>
    );
  }
  
  