import React from "react";
import { Breadcrumbs } from "../components/breadcrumbs";
import Card from "../components/card";
import { useNuggets } from "../helpers/nuggetContext";

const Opportunities = () => {
    
        var headerStyle = {
            backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://images.unsplash.com/photo-1598257006626-48b0c252070d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
         };

        const nuggets = useNuggets();
      
        return (
            <>
                <h2 className="page-header" style={headerStyle}>Opportunities</h2>
                <Breadcrumbs/>
       
                <section className="section">
                <h4>We are always on the look out for good people to join our team and help deliver value to our clients.</h4>
                    <h4>We employ people at every stage of their career, from leaving school to retirement.</h4>
                    <h4>We also engage with people on flexible terms: full-time or part-time; permanent or temporary.</h4>
                    <h4>Follow one of the links below to find out more.</h4>
                </section>

                <section id="section-opportunities" className="section">
                    <div id="delivery-cards" className="cards-container" >
                        {nuggets
                        .filter((nug) => nug.type === "Recruitment")
                        .map(({ id, title, description, image }) => (
                            <Card 
                            key={id} 
                            title={title} 
                            description={description}
                            navigateTo = {"/opportunities/"+title}
                            image={image} />
                        ))}
                    </div>
                </section>
                
        </>
    );
};

export default Opportunities;