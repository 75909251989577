import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import debounce from 'lodash/debounce';

export const StackedBarChart = ({ data, selected, handleClick }) => {

  const [width, setWidth] = useState(0);
  const containerRef = useRef("no selection");
  const xDimension = "category";
  const yDimension = "value";
  const zDimension = "id";

  useEffect(() => {
    function updateWidth() {
      setWidth(containerRef.current.clientWidth / (data.length + 1));
    }
    const handleResize = debounce(updateWidth, 500);
    updateWidth();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data.length]);

  useLayoutEffect(() => {
    if (Array.isArray(data) && containerRef?.current) {
      
      // format as nested objects in dimensions x and z  
      // note that this code does some grouping for the values, but...
      // only the first data item of each group is attcahed as data for the purpose of handling selection/.clicks
      let formattedData = data.reduce(function(res, curr) {
        if (!res[curr[xDimension]]) res[curr[xDimension]] = {};
        if (!res[curr[xDimension]][curr[zDimension]]) res[curr[xDimension]][curr[zDimension]] = {"x":curr[xDimension], "y":0, "z":curr[zDimension], "data":curr};
        res[curr[xDimension]][curr[zDimension]].y += curr[yDimension];
        return res;
      }, {});
      console.log(formattedData);

      // add the running totals to each nested element
      for (let x in formattedData){
        let y0 = 0;
        for(let z in formattedData[x]) {
          formattedData[x][z].y0= y0;
          y0 += formattedData[x][z].y;
          formattedData[x][z].y1 = y0;
        }
      }
      
      // set layout parameters
      const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400"];
      const max_bar_width = 100;
      const svg_height = 350;
      const top_offset = 50;
      const bottom_offset = 50;
      
      // create svg
      const svg = d3.select(containerRef?.current)
                  .attr("fill", "red")
                  .attr("width", '100%')
                  .attr("height", svg_height);

      // to make graph responsive calcuate we set width 100%
      // here we calculate width in pixels
      const svg_width = svg.node().getBoundingClientRect().width;
      
      // decide bar width depending upon available space and no. of bars to plot
      let bar_width = Math.round((svg_width - 60) / data.length);
      if (bar_width > max_bar_width) {
        bar_width = max_bar_width;
      }
     
      // Set up the scales
      const xScale = d3.scaleBand()
          .domain(Object.keys(formattedData))
          .range([0, svg_width])
          .padding(0.1);
      
      const yScalePosition = d3.scaleLinear()
        .domain([0, d3.max(Object.values(formattedData).flatMap(d => Object.values(d).flatMap(d => d.y1)))])
        .range([svg_height - bottom_offset, 0]);

      const yScaleHeight = d3.scaleLinear()
        .domain([0, d3.max(Object.values(formattedData).flatMap(d => Object.values(d).flatMap(d => d.y1)))])
        .range([0,svg_height - top_offset]);
    
      // Create a selection of groups for each top-level key
      const groups = svg.selectAll('g')
      .data(Object.entries(formattedData))
      .join('g')
        .attr('transform', d => `translate(${xScale(d[0])}, 0)`);

      // Create a selection of bars for each nested key
      const bars = groups.selectAll('rect')
      .data(d => Object.entries(d[1]))
      .join('rect')
        .on("click", (e, d) => {
          return (handleClick(d[1].data));
        })
        .attr('x', 0)
        .attr('y', d => yScalePosition(d[1].y1))
        .attr('width', xScale.bandwidth())
        .attr('height', d => yScaleHeight(d[1].y))
        .attr("fill", (d, i) => colors[(d[1].z-1) % 5])
        .attr("opacity", (d) =>  ((d[1].data?.id === selected?.id || !selected  ? 1 : 0.2)))
        ;
        
    }   
  }, [data, selected, width]);

  return (
     <svg width="100%" height="350px" ref={containerRef}/>
  );
};