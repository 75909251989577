import React from "react";
import Card from "../components/card";
import { useNuggets } from "../helpers/nuggetContext";
import { NavLink } from "react-router-dom";
import { Breadcrumbs } from "../components/breadcrumbs";
import Minicard from "../components/minicard";

const Services = () => {


    const nuggets = useNuggets();

    var headerStyle = {
        backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://blog.planview.com/wp-content/uploads/2014/01/AAEAAQAAAAAAAAOOAAAAJDVhMGZhZmJkLTJlMDQtNDExOS05YTliLTAyYWVkMTY5MmUzOQ.jpg)",
     };
      
    return (
        <>
            <h2 className="page-header" style={headerStyle}>Services</h2>
            <Breadcrumbs/>
            
            <section className="section">
                <h4>We know a lot about insurance, but that's not all we do.</h4>
                <h4>We are happy to tell you more about all of the services we offer.</h4>
                <h4>If you would like a more tailored description of services to your industry, follow the links below.</h4>
            </section>

            <section className="section">
                <div id="industry-cards" className="cards-container" >
                    {nuggets
                    .filter((nug) => nug.type === "Industry")
                    .map(({ id, title, description, image }) => (
                        <Card 
                        key={id} 
                        title={title} 
                        description={description} 
                        navigateTo = {"/services/"+title}
                        image={image} />
                    ))}
                </div>
            </section>
           
        </>
    );
};

export default Services;