import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import debounce from 'lodash/debounce';

export const PieChart = ({ data, selected, handleClick }) => {

  const [width, setWidth] = useState(0);
  const containerRef = useRef("no selection");

  useEffect(() => {
    function updateWidth() {
      setWidth(containerRef.current.clientWidth / (data.length + 1));
    }
    const handleResize = debounce(updateWidth, 500);
    updateWidth();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [data.length]);

  useLayoutEffect(() => {
    if (Array.isArray(data) && containerRef?.current) {
      
        const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400"];
        const boxSize = 500;
        
        const arcGenerator = d3.arc().innerRadius(0).outerRadius(250);
        const pieGenerator = d3.pie().value((d) => d.value);
      
        const A = pieGenerator(data);
        const B = pieGenerator(data);
        console.log(pieGenerator(data));
        console.log(A[1]==B[1]);
        console.log(A[1][data]==B[1][data]);
        console.log(containerRef);

        const svg = d3
          .select(containerRef?.current)
          // .append("svg")
          .attr("preserveAspectRatio", "xMidYMid meet")
          .attr("height", "100%")
          .attr("width", "100%")
          .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
          // // .select("g") 
          .select(".my-pie") 
          //.append("g")
          .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`)
          .selectAll("path")
          .data((pieGenerator(data)))
          .join("path")
          // .append("path")
          .on("click", (d) => {
            return (handleClick(d.target.__data__.data));
          })
          .attr("d", arcGenerator)
          .style("opacity", (d) =>  ((d.data == selected || !selected  ? 1 : 0.2)))
          .style("fill", (d, i) => colors[i % data.length])
          ;
        
        svg.exit().remove();
    }   
  }, [data, selected, width]);

  return (
     <svg width="100%" height="350px" ref={containerRef} preserveAspectRatio='xMidYMid meet'>
    {/* <svg ref={containerRef}> */}
      <g transform="translate(0, 100)" className="my-pie"/>
    </svg>
  );
};