import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import logo from '../assets/logo.png';

export default function Header() {

    const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false);

    return (
      <>
        <header className="title-bar">

          <nav className="full-screen-nav">
            <NavLink className="logo" to="/">
              <img src={logo} className="App-logo-header" alt="logo" />
              <img src={logo} className="App-logo-header" alt="logo" />
              <img src={logo} className="App-logo-header" alt="logo" />
            </NavLink>
            
            <ul>
              <li key="srv"><NavLink to="/services">Services</NavLink></li>
              <li key="opp"><NavLink to="/opportunities">Opportunities</NavLink></li>
              <li key="rsr"><NavLink to="/resources">Resources</NavLink></li>
            </ul>

           
          </nav>
          {/* <NavMenu/> */}
          
          <div id="nav-icon1" className={hamburgerIsOpen ? "hamburger open" : "hamburger"} onClick={() => setHamburgerIsOpen(!hamburgerIsOpen)}>
              <span></span>
              <span></span>
              <span></span>
          </div>

          <ul className={hamburgerIsOpen ? "mobile-nav open" : "mobile-nav"} onClick={() => setHamburgerIsOpen(false)}>
              <li key="hom"><NavLink to="/">Home</NavLink></li>
              <li key="srv"><NavLink to="/services">Services</NavLink></li>
              <li key="opp"><NavLink to="/opportunities">Opportunities</NavLink></li>
              <li key="rsr"><NavLink to="/resources">Resources</NavLink></li>
          </ul>


        </header>

        <div className="title-bar-margin"></div>
       
      </>
    );
  }
  
  