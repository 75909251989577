import React from "react";
import Card from "../components/card";
import { useNuggets } from "../helpers/nuggetContext";
import { Breadcrumbs } from "../components/breadcrumbs";
import { useParams } from "react-router-dom";
import Minicard from "../components/minicard";
import { NavLink } from "react-router-dom";
import PictureSlice from "../components/pictureSlice";
import Highlights from "../components/highlights";

const Industry = () => {


    const params = useParams();
    const nuggets = useNuggets();
    var headerStyle = {
        backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://blog.planview.com/wp-content/uploads/2014/01/AAEAAQAAAAAAAAOOAAAAJDVhMGZhZmJkLTJlMDQtNDExOS05YTliLTAyYWVkMTY5MmUzOQ.jpg)",
    };
    
    return (
        <>
            <h2 className="page-header" style={headerStyle}>{"Information for Clients: "+params.industry}</h2>
            <Breadcrumbs/>
            
            <section className="section">
                { params.industry === "Life Assurance" &&
                    <div>
                        <h2>We have 15 years of experience in Life Assurance.</h2>
                        <h2>I mean, we haven't had much of a life in that time, but dude... we know what we are talking about.</h2>
                    </div>
                }
                { params.industry === "General Insurance" &&
                <div>
                        <h2>We have 5 years of experience in General Insurance.</h2>
                        <h2>I mean, generally we've had a lot of experience in general... both general specialist lines and general in... er... general.</h2>
                </div>
                }
                { params.industry === "Other Industries" &&
                    <div>
                        <h2>We have about 5 mintues of experience in Other Industries.</h2>
                        <h2>But once you know insurance, everything else is easy, right?</h2>
                    </div>
                }
            </section>
            
            <section id="section-departments" className="section">
                <div className="section-panel">
                    <h4>We work with many client functions...</h4>
                    <ul className='simple-list'>
                    {nuggets
                        .filter((nug) => nug.type === "Department")
                        .filter((nug) => nug.industry?.includes(params.industry))
                        .slice(0, 11)
                        .map(({ title, id }) => (
                        <li key={id}>
                            <NavLink 
                            to={"/resources/"+id}
                            replace={false} 
                            className="list-link">
                                {title}
                            </NavLink>
                        
                        </li>
                    ))}
                    </ul>
                </div>
                <div className="section-panel">
                    <h4>..helping with a variety of activities.</h4>
                    <ul className='simple-list'>
                    {nuggets
                        .filter((nug) => nug.type === "Activity")
                        .filter((nug) => nug.industry?.includes(params.industry))
                        .slice(0, 11)
                        .map(({ title, id }) => (
                        <li key={id}>
                            <NavLink 
                            to={"/resources/"+id}
                            replace={false} 
                            className="list-link">
                                {title}
                            </NavLink>
                        </li>
                    ))}
                    </ul>
                </div>
            </section>

            <section id="section-delivery" className="section">
                <h4>We help deliver projects in four different ways:</h4>
                <div id="delivery-cards" className="cards-container" >
                    {nuggets
                        .filter((nug) => nug.type === "Delivery")
                        .map(({ id, title, description, image }) => (
                        <Minicard 
                            key={id} 
                            title={title} 
                            navigateTo = {"/resources/"+id}
                            image={image} />
                    ))}
                </div>
            </section>

            <section id="section-expertise" className="section">
                <h4>
                    We have a depth of experience in a wide range of topics...
                </h4>
                <div className="cards-container">
                {nuggets
                    .filter((nug) => nug.type === "Expertise")
                    .filter((nug) => nug.industry?.includes(params.industry))
                    .slice(0, 12)
                    .map(({ id, title, description, image}) => (
                    <Card 
                        key={id} 
                        title={title} 
                        description={description} 
                        navigateTo = {"/resources/"+id}
                        image={image} />
                ))}
                </div>
            </section>

            <section id="section-highlights" className="section">
                <h4>We don't rest on our laurels, but we are proud of our track record.</h4>
                <Highlights/>
            </section>

            <section id="section-testimonials" className="section">
            <div className="picture-slice-container">
                    <h4>
                    This is what some of our clients think of us...
                    </h4>
                {nuggets
                    .filter((nug) => nug.type === "Testimonial")
                    .slice(0, 3)
                    .map((nug, idx) => (
                    <PictureSlice 
                        key={nug.id} 
                        description={nug.title + ": " + nug.description} 
                        content={nug.content}
                        pictureOnLeft={idx % 2 == 1}
                        image={nug.image} />
                ))}
            </div>
            </section>
        </>
    );
};

export default Industry;