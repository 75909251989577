import { Link, useLocation } from "react-router-dom";

export function Breadcrumbs() {
  const location = useLocation();

  // Split the pathname into an array of segments
  const segments = location.pathname.split("/").filter((segment) => segment);

  // Generate a breadcrumb element for each segment
  const breadcrumbs = segments.map((segment, index) => {
    // Construct the path up to this segment
    const path = `/${segments.slice(0, index + 1).join("/")}`;

    // Use the segment as the link text, except for the last segment
    const text = index === segments.length - 1 ? segment : segment.replace(/-/g, " ");

    // Render a link for all segments except the last one
    if (index < segments.length - 1) {
      return (
        <li key={segment}>
          <Link to={path}>{text}</Link>
          <span> > </span>
        </li>
      );
    }

    // Render plain text for the last segment
    return (
      <li key={segment}>
        {text.replace(/%20/g, " ")}
      </li>
    );
  });

  return (
    <nav className="breadcrumbs" aria-label="breadcrumb">
      <ul>
        <li>
          <Link to="/">home</Link>
          <span> > </span>
        </li>
        {breadcrumbs}
      </ul>
    </nav>
  );
}


