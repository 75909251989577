import React from "react";
import { NavLink } from "react-router-dom";
import logo from '../assets/logo.png';

export default function Footer() {

    return (
      <div className="footer">
         <div className="footer-intro">
            <img src={logo} className="App-logo-header" alt="logo" />
            <img src={logo} className="App-logo-header" alt="logo" />
            <img src={logo} className="App-logo-header" alt="logo" />
            <h4>ABC Consulting</h4>
          </div>
          <div>
          <form id="contact-form">
              <input type="email" className="form-control" id="email-contact" placeholder="Enter email"/>
              <button type="button" className="btn" id="email-button">Send</button>
              <textarea className="form-control" id="email-message" placeholder="Message"></textarea>
          </form>
          </div>
          <div className="footer-columns">
          <div className="footer-navigation-section">
            <ul>
              <li><NavLink to="/services">Services</NavLink></li>
              <li><NavLink to="/services/Life%20Assurance">Life Assurance</NavLink></li>
              <li><NavLink to="/services/General%20Insurance">General Insurance</NavLink></li>
              <li><NavLink to="/services/Other%20Industries">Other Industries</NavLink></li>
            </ul>
          </div>  
          <div className="footer-navigation-section">
            <ul>
              <li><NavLink to="/opportunities">Opportunities</NavLink></li>
              <li><NavLink to="/opportunities/School Leavers">School Leavers</NavLink></li>
              <li><NavLink to="/opportunities/Graduates">Graduates</NavLink></li>
              <li><NavLink to="/opportunities/Professionals">Professionals</NavLink></li>
              <li><NavLink to="/opportunities/Contractors">Contractors</NavLink></li>
            </ul>
          </div> 
          <div className="footer-navigation-section">
            <ul>
              <li><NavLink to="/resources">Resources</NavLink></li>
              <li><NavLink to="/resources" state={{type: "Person"}}>Meet the Team</NavLink></li>
              <li><NavLink to="/resources" state={{type: "Article"}}>News and Views</NavLink></li>
              <li><NavLink to="/resources" state={{type: "Expertise"}}>Expertise</NavLink></li>
              <li><NavLink to="/resources" state={{type: "Project"}}>Projects</NavLink></li>
            </ul>
          </div>   
          </div>
       
      </div>
    );
  }
  
  