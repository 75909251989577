import React, { useState, useEffect } from "react";
import { useNuggets } from "../helpers/nuggetContext";
import { Breadcrumbs } from "../components/breadcrumbs";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Resources = () => {

    let location = useLocation();
    // const restrictType = location.state?.type;

    const nuggets = useNuggets();
    const [filterText, setFilterText] = useState("");
    const [filterType, setFilterType] = useState("no_selection");
    const handleChangeFilterText = (e) => {setFilterText(e.target.value)};
    const handleChangeFilterType = (e) => {setFilterType(e.target.value)};

    useEffect (() => {
        setFilterType(location.state?.type || "no_selection")
    }, [location])

    
    var headerStyle = {
        backgroundImage: "linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url(https://blog.planview.com/wp-content/uploads/2015/03/3asset.jpg",
    };

    return (
        <>
            <h2 className="page-header" style={headerStyle}>{location.state?.type || "Resources"}</h2>
            <Breadcrumbs/>
   
            <section className="section">
                <h5>This page contains a list of all the nuggets.</h5>
                <h5>This may not be part of a usual corporate website,
                     but it does make it easy to understand the nugget structure.</h5>
                <h5>We could/should add a lot more details to the nuggets (e.g. author and date)
                    and controls (e.g. sort by date).</h5>
                
                <form id="resource-selector-form">
                    <input 
                        type="search" 
                        className = "resources-search"
                        value={filterText} 
                        onChange={handleChangeFilterText} 
                        placeholder="Enter search text"/>
                    {/* <label for="filter-by-type">Choose type:</label> */}
                    {true && <select 
                        id="filter-by-type" 
                        onChange={handleChangeFilterType}
                        name="type">
                            <option key="no_selection" value="no_selection">{filterType}</option>
                        {nuggets
                            .map(nug => nug.type)
                            .filter((item, i, ar) => ar.indexOf(item) === i)
                            .sort()
                            .map((typ) => {return(
                                <option key={typ} value={typ}>{typ}</option>
                            )})}
                    </select>}
                    
                    
                </form>

                <table className="resources-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Type</th>
                            <th>Content</th>
                            <th></th>
                        </tr>      
                    </thead>
                    <tbody>
                        {nuggets
                        .map(nug => ({ ...nug, SearchText: (nug.title + ":" + nug.type + ":" + nug.description).toLowerCase()}))
                        .filter((nug) => {return (filterText ?
                            nug.SearchText.includes(filterText.toLowerCase()) :
                            true)})
                        .filter((nug) => {return (nug.type == filterType || filterType == "no_selection")})
                        .sort((nug) => !nug.title.toLowerCase().includes(filterText.toLowerCase()))
                        .map(({ id, title, description, type, image }) => (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{type}</td>
                                <td>
                                    <NavLink className="navlink" to={"/resources/"+id} replace={false}>
                                        <img src={image} className="nugget-thumbnail" alt=""/>
                                    </NavLink>
                                </td>
                                <td>
                                    <NavLink className="navlink" to={"/resources/"+id} replace={false}>{title}</NavLink>
                                    <br/>
                                    {description.substring(0,50)+"..."}
                                </td>
                            </tr>   
                        ))}
                    </tbody>
                </table>
            </section>
            
        </>
    );
};

export default Resources;