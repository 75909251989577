import './App.css';
import { Routes, Route, NavLink} from 'react-router-dom';
import Home from './pages/home.js'
import Services from './pages/services'
import Opportunities from './pages/opportunities.js'
import Resources from './pages/resources';
import Industry from './pages/industry';
import Nugget from './pages/nugget';
import RecruitmentType from './pages/recruitmentType';
import Footer from './components/footer';
import Header from './components/header';
import Test from './pages/test';

function App() {

  
  return (
      <div className="App">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"/>
        
        <Header/>

        <Routes>
          <Route path = "/" element = {<Home />} />
          <Route path = "/services">
            <Route index element = {<Services />} />
            <Route path = "/services/:industry" element = {<Industry />} />
          </Route>
          <Route path = "/opportunities">
            <Route index element = {<Opportunities />} />
            <Route path = "/opportunities/:type" element = {<RecruitmentType />} />
          </Route>
          <Route path = "/resources">
            <Route index element = {<Resources />} />
            <Route path = "/resources/:id" element = {<Nugget />} />
          </Route>
          <Route path="/test" element = {<Test/>} />
          
        </Routes>

        <Footer/>
      


      </div>
    
   
  );
}

export default App;
