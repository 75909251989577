import React from "react";
import { NavLink } from "react-router-dom";

export default function PictureCard({ title, image, navigateTo }) {

  
    return (
      <div className="picture-card">
        <img className="picture-card-image" src={image} alt=""/>
        {navigateTo && <NavLink 
          to={navigateTo} 
          replace={false} 
          className="picture-card-title">
            <h3>{title}</h3>
        </NavLink>}
      </div>
    );
  }
  
  