import React from "react";
import { FaGrimace, FaUserInjured, FaUserGraduate, FaPeopleArrows} from "react-icons/fa";

export default function Highlights(){
    
    return (
        <div>
            <div className="highlight-reel">
                <div className="highlight">
                    <FaGrimace size={70}/>
                    <h4>1000+ happy clients</h4>
                </div>
                <div className="highlight">
                    <FaUserGraduate size={70}/>
                    <h4>110% exam pass rate</h4>
                </div>
                <div className="highlight">
                    <FaPeopleArrows size={70}/>
                    <h4>Investors in People Award</h4>
                </div>
                <div className="highlight">
                    <FaUserInjured size={70}/>
                    <h4>Zero accidents in last 3.5 days</h4>
                </div>
            </div>
        </div>
    );

}

    
